@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
    url('../assets/fonts/Poppins/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
    url('../assets/fonts/Poppins/Poppins-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
